<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_40458_396059)">
      <path
        d="M10.6445 18.145C14.7867 18.145 18.1445 14.7872 18.1445 10.645C18.1445 6.50288 14.7867 3.14502 10.6445 3.14502C6.5024 3.14502 3.14453 6.50288 3.14453 10.645C3.14453 14.7872 6.5024 18.145 10.6445 18.145Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M21.1445 21.145L16.1445 16.145"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_40458_396059">
        <rect
          width="19.71"
          height="19.71"
          fill="white"
          transform="translate(2.14453 2.14502)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
